import creatorImg from '../images/creator.png';
import hologramImg from '../images/hologram.png';
import uniteplusImg from '../images/unite+/unite.png';
import expoImg from '../images/unite/video2.png';
import liveImg from '../images/LiveStreaming/livestreaming.png';
import analyticsImg from '../images/analytics/analytics.png';
import udyogImg from '../images/udyog/udyog.png'
import melzoexpoImg from '../images/expo/ILLUSTRATION.png'
import customizerImg from '../images/CUSTOMIZER/illustration.png'

// windows.isPaused = isPaused;
window.isPaused = false;
window.productTimer = function productTimer() {
    setInterval(onTimerElapsed, 5000);
}

window.addEventListener('load', () => {
    productTimer();
    // clientTimer();
    // productTimer2();

});
window.onTimerElapsed = function onTimerElapsed() {
    var creatorBlock = document.getElementById("creatorInfo");
    var guruBlock = document.getElementById("guruInfo");
    var expoBlock = document.getElementById("expoInfo");
    var talkbotBlock = document.getElementById("talkbotInfo");
    var liveBlock = document.getElementById("liveInfo");
    var hologramBlock = document.getElementById("hologramInfo");
    var anyliticsBlock = document.getElementById("anyliticsInfo");
    var melzoexpoBlock = document.getElementById("melzoexpoInfo");
    var customizerBlock = document.getElementById("customizerInfo");

    var isPaused = false;

    // console.log('isPaused => ', window.isPaused);

    if (!window.isPaused) {

        var firstLI = document.getElementById("product_list").firstElementChild;
        var lastLI = document.getElementById("product_list").lastElementChild;

        var list = document.getElementById("product_list"); // Get the <ul> element to insert a new node

        setTimeout(function () {
            list.children[1].classList.add("rot-pos-1");
            list.children[2].classList.add("rot-pos-2");
            list.children[3].classList.add("rot-pos-3");
            list.children[4].classList.add("rot-pos-4");
            list.children[5].classList.add("rot-pos-5");
            list.children[6].classList.add("rot-pos-6");
            list.children[7].classList.add("rot-pos-7");
            list.children[8].classList.add("rot-pos-8");
            list.children[9].classList.add("rot-pos-9");


            setTimeout(() => {
                list.children[1].classList.remove("rot-pos-1");
                list.children[2].classList.remove("rot-pos-2");
                list.children[3].classList.remove("rot-pos-3");
                list.children[4].classList.remove("rot-pos-4");
                list.children[5].classList.remove("rot-pos-5");
                list.children[6].classList.remove("rot-pos-6");
                list.children[7].classList.remove("rot-pos-7");
                list.children[8].classList.remove("rot-pos-8");
                list.children[9].classList.remove("rot-pos-9");

            }, 1000);
        }, 10);
        firstLI.insertAdjacentElement("afterend", lastLI);

        var currentId = list.children[5].id;


        if (currentId === 'creator') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');

            imgEl.src = creatorImg;

            creatorBlock.classList.add('slide-in');

            setTimeout(() => {
                creatorBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'block';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'none';


        } else if (currentId === 'hologram') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = hologramImg;

            hologramBlock.classList.add('slide-in');

            setTimeout(() => {
                hologramBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'block';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'none';

        } else if (currentId === 'guru') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = uniteplusImg;

            guruBlock.classList.add('slide-in');

            setTimeout(() => {
                guruBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'block';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'none';

        } else if (currentId === 'anylitics') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = analyticsImg;

            anyliticsBlock.classList.add('slide-in');

            setTimeout(() => {
                anyliticsBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'block';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'none';

        } else if (currentId === 'melzoexpo') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = melzoexpoImg;

            melzoexpoBlock.classList.add('slide-in');

            setTimeout(() => {
                melzoexpoBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'block';
            customizerBlock.style.display = 'none';

        } else if (currentId === 'expo') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = expoImg;

            expoBlock.classList.add('slide-in');

            setTimeout(() => {
                expoBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'block';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'none';

        } else if (currentId === 'talkbot') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = udyogImg;

            hologramBlock.classList.add('slide-in');

            setTimeout(() => {
                hologramBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'block';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'none';

        } else if (currentId === 'live') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = liveImg;

            liveBlock.classList.add('slide-in');

            setTimeout(() => {
                liveBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'block';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'none';

        } else if (currentId === 'customizer') {
            var imgEl = document.getElementById('productImage');
            imgEl.classList.add('rotate');
            imgEl.src = customizerImg;

            customizerBlock.classList.add('slide-in');

            setTimeout(() => {
                customizerBlock.classList.remove('slide-in');
                imgEl.classList.remove('rotate');
            }, 3000);

            creatorBlock.style.display = 'none';
            guruBlock.style.display = 'none';
            expoBlock.style.display = 'none';
            talkbotBlock.style.display = 'none';
            liveBlock.style.display = 'none';
            hologramBlock.style.display = 'none';
            anyliticsBlock.style.display = 'none';
            melzoexpoBlock.style.display = 'none';
            customizerBlock.style.display = 'block';

        }
    }
    // list.append(firstLI.nextElementSibling());  // Insert <li> before the first child of <ul>
    // this.productTimer();
}

window.changeContent = function changeContent(currentId) {

    var creatorBlock = document.getElementById("creatorInfo");
    var guruBlock = document.getElementById("guruInfo");
    var expoBlock = document.getElementById("expoInfo");
    var talkbotBlock = document.getElementById("talkbotInfo");
    var liveBlock = document.getElementById("liveInfo");
    var hologramBlock = document.getElementById("hologramInfo");
    var anyliticsBlock = document.getElementById("anyliticsInfo");
    var melzoexpoBlock = document.getElementById("melzoexpoInfo");
    var customizerBlock = document.getElementById("customizerInfo");

    var isPaused = false;

    var list = document.getElementById("product_list");

    // list.addEventListener("click", function (e) {
    //     if (e.target && e.target.matches("li.item")) {
    //         e.target.className = "foo"; // new class name here
    //         alert("clicked " + e.target.innerText);
    //     }
    // });

    window.isPaused = true;

    // console.log('isPaused => ', window.isPaused);

    setTimeout(function () {
        list.children[1].classList.add("rot-pos-1");
        list.children[2].classList.add("rot-pos-2");;
        list.children[3].classList.add("rot-pos-3");
        list.children[4].classList.add("rot-pos-4");
        list.children[5].classList.add("rot-pos-5");
        list.children[6].classList.add("rot-pos-6");
        list.children[7].classList.add("rot-pos-7");
        list.children[8].classList.add("rot-pos-8");
        list.children[9].classList.add("rot-pos-9");


        setTimeout(() => {
            list.children[1].classList.remove("rot-pos-1");
            list.children[2].classList.remove("rot-pos-2");;
            list.children[3].classList.remove("rot-pos-3");
            list.children[4].classList.remove("rot-pos-4");
            list.children[5].classList.remove("rot-pos-5");
            list.children[6].classList.remove("rot-pos-6");
            list.children[7].classList.remove("rot-pos-7");
            list.children[8].classList.remove("rot-pos-8");
            list.children[9].classList.remove("rot-pos-9");
        }, 1000);
    }, 10);

    var firstLI = document.getElementById("product_list").firstElementChild;

    var creatorLi = document.getElementById("creator");
    var hologramLi = document.getElementById("hologram");
    var guruLi = document.getElementById("guru");
    var anyliticsLi = document.getElementById("anylitics");
    var expoLi = document.getElementById("expo");
    var talkbotLi = document.getElementById("talkbot");
    var liveLi = document.getElementById("live");
    var customizerLi = document.getElementById("customizer");
    var melzoexpoLi = document.getElementById("melzoexpo");


    setTimeout(() => {
        window.isPaused = false;
        // console.log('isPaused => ', window.isPaused);
    }, 15000);

    if (currentId === 'creator') {

        firstLI.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", guruLi);
        guruLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", liveLi);
        liveLi.insertAdjacentElement("afterend", hologramLi);
        hologramLi.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", melzoexpoLi);
        melzoexpoLi.insertAdjacentElement("afterend", customizerLi);


        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = creatorImg;

        creatorBlock.classList.add('slide-in');

        setTimeout(() => {
            creatorBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'block';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'none';

    } else if (currentId === 'hologram') {


        firstLI.insertAdjacentElement("afterend", hologramLi);
        hologramLi.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", melzoexpoLi);
        melzoexpoLi.insertAdjacentElement("afterend", customizerLi);
        customizerLi.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insert
        guruLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", liveLi);
        liveLi.insertAdjacentElement("afterend", hologramLi);
        



        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = hologramImg;

        hologramBlock.classList.add('slide-in');

        setTimeout(() => {
            hologramBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'block';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'none';
    } else if (currentId === 'guru') {

        firstLI.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", melzoexpoLi);

        melzoexpoLi.insertAdjacentElement("afterend", liveLi);
        liveLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", guruLi);
        guruLi.insertAdjacentElement("afterend", customizerLi);

        customizerLi.insertAdjacentElement("afterend", expoLi);

        expoLi.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", hologramLi);

        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = uniteplusImg;

        guruBlock.classList.add('slide-in');
        setTimeout(() => {
            guruBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'block';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'none';
    } else if (currentId === 'anylitics') {

        firstLI.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", melzoexpoLi);

        melzoexpoLi.insertAdjacentElement("afterend", hologramLi);
        hologramLi.insertAdjacentElement("afterend", guruLi);
        guruLi.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", customizerLi);

        customizerLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", liveLi);
        liveLi.insertAdjacentElement("afterend", talkbotLi);


        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = analyticsImg;

        anyliticsBlock.classList.add('slide-in');
        setTimeout(() => {
            anyliticsBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'block';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'none';
    } else if (currentId === 'expo') {

        firstLI.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", customizerLi);

        customizerLi.insertAdjacentElement("afterend", hologramLi);
        hologramLi.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", melzoexpoLi);

        melzoexpoLi.insertAdjacentElement("afterend", liveLi);
        liveLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", guruLi);


        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = expoImg;

        expoBlock.classList.add('slide-in');
        setTimeout(() => {
            expoBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'block';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'none';
    } else if (currentId === 'talkbot') {

        firstLI.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", customizerLi);

        customizerLi.insertAdjacentElement("afterend", guruLi);
        guruLi.insertAdjacentElement("afterend", liveLi);
        liveLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", melzoexpoLi);

        melzoexpoLi.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", hologramLi);


        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = udyogImg;

        hologramBlock.classList.add('slide-in');
        setTimeout(() => {
            hologramBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'block';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'none';
    } else if (currentId === 'live') {

        firstLI.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", customizerLi);

        customizerLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", liveLi);
        liveLi.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", hologramLi);
        hologramLi.insertAdjacentElement("afterend", guruLi);
        guruLi.insertAdjacentElement("afterend", melzoexpoLi);



        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = liveImg;

        liveBlock.classList.add('slide-in');
        setTimeout(() => {
            liveBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'block';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'none';
    } else if (currentId === 'customizer') {

        firstLI.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", liveLi);

        liveLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", customizerLi);
        customizerLi.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", hologramLi);
        hologramLi.insertAdjacentElement("afterend", guruLi);
        guruLi.insertAdjacentElement("afterend", melzoexpoLi);



        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = customizerImg;

        customizerBlock.classList.add('slide-in');

        setTimeout(() => {
            customizerBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'none';
        customizerBlock.style.display = 'block';
    } else if (currentId === 'melzoexpo') {

        firstLI.insertAdjacentElement("afterend", anyliticsLi);
        anyliticsLi.insertAdjacentElement("afterend", customizerLi);

        customizerLi.insertAdjacentElement("afterend", expoLi);
        expoLi.insertAdjacentElement("afterend", talkbotLi);
        talkbotLi.insertAdjacentElement("afterend", melzoexpoLi);
        melzoexpoLi.insertAdjacentElement("afterend", creatorLi);
        creatorLi.insertAdjacentElement("afterend", hologramLi);
        hologramLi.insertAdjacentElement("afterend", guruLi);
        guruLi.insertAdjacentElement("afterend", liveLi);



        var imgEl = document.getElementById('productImage');
        imgEl.classList.add('rotate');
        imgEl.src = melzoexpoImg;

        melzoexpoBlock.classList.add('slide-in');

        setTimeout(() => {
            melzoexpoBlock.classList.remove('slide-in');
            imgEl.classList.remove('rotate');
        }, 3000);

        creatorBlock.style.display = 'none';
        guruBlock.style.display = 'none';
        expoBlock.style.display = 'none';
        talkbotBlock.style.display = 'none';
        liveBlock.style.display = 'none';
        hologramBlock.style.display = 'none';
        anyliticsBlock.style.display = 'none';
        melzoexpoBlock.style.display = 'block';
        customizerBlock.style.display = 'none';
    }
}





// clientTimer();







// window.changeContent1 = function changeContent1(currentId) {




//     var Clientname1 = document.getElementById("clientname1");
//     var Clientname2 = document.getElementById("clientname2");
//     var Clientname3 = document.getElementById("clientname3");
//     var Clientname4 = document.getElementById("clientname4");
//     var Clientname5 = document.getElementById("clientname5");
//     var Clientname6 = document.getElementById("clientname6");
//     var Clientname7 = document.getElementById("clientname7");




//     var isPaused = false;

//     var list = document.getElementById("product_listclients");

//     list.addEventListener("click", function (e) {
//         if (e.target && e.target.matches("li.item")) {
//             e.target.className = "foo";

//             alert("clicked " + e.target.innerText);
//         }
//     });

//     window.isPaused = true;


//     var firstLI = document.getElementById("product_listclients").firstElementChild;

//     var Client1Li = document.getElementById("client1");
//     var Client2Li = document.getElementById("client2");
//     var Client3Li = document.getElementById("client3");
//     var Client4Li = document.getElementById("client4");
//     var Client5Li = document.getElementById("client5");
//     var Client6Li = document.getElementById("client6");
//     var Client7Li = document.getElementById("client7");


//     setTimeout(() => {
//         window.isPaused = false;

//     }, 15000);

//     if (currentId === 'client1') {

//         firstLI.insertAdjacentElement("afterend", Client2Li);

//         Client2Li.insertAdjacentElement("afterend", Client3Li);
//         Client3Li.insertAdjacentElement("afterend", Client4Li);
//         Client4Li.insertAdjacentElement("afterend", Client1Li);
//         Client1Li.insertAdjacentElement("afterend", Client5Li);
//         Client5Li.insertAdjacentElement("afterend", Client6Li);
//         Client6Li.insertAdjacentElement("afterend", Client7Li);



//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         Clientname1.style.display = 'block';
//         Clientname2.style.display = 'none';
//         Clientname3.style.display = 'none';
//         Clientname4.style.display = 'none';
//         Clientname5.style.display = 'none';
//         Clientname6.style.display = 'none';
//         Clientname7.style.display = 'none';
//     } else if (currentId === 'client2') {


//         firstLI.insertAdjacentElement("afterend", Client1Li);
//         Client1Li.insertAdjacentElement("afterend", Client3Li);

//         Client3Li.insertAdjacentElement("afterend", Client4Li);
//         Client4Li.insertAdjacentElement("afterend", Client2Li);
//         Client2Li.insertAdjacentElement("afterend", Client5Li);
//         Client5Li.insertAdjacentElement("afterend", Client6Li);
//         Client6Li.insertAdjacentElement("afterend", Client7Li);




//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = hologramImg;

//         // hologramBlock.classList.add('slide-in');

//         // setTimeout(() => {
//         //     hologramBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);

//         Clientname1.style.display = 'none';
//         Clientname2.style.display = 'block';
//         Clientname3.style.display = 'none';
//         Clientname4.style.display = 'none';
//         Clientname5.style.display = 'none';
//         Clientname6.style.display = 'none';
//         Clientname7.style.display = 'none';
//     } else if (currentId === 'client3') {

//         firstLI.insertAdjacentElement("afterend", Client1Li);

//         Client1Li.insertAdjacentElement("afterend", Client2Li);
//         Client2Li.insertAdjacentElement("afterend", Client4Li);
//         Client4Li.insertAdjacentElement("afterend", Client3Li);
//         Client3Li.insertAdjacentElement("afterend", Client5Li);
//         Client5Li.insertAdjacentElement("afterend", Client6Li);
//         Client6Li.insertAdjacentElement("afterend", Client7Li);


//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = uniteplusImg;

//         // guruBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     guruBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);


//         Clientname1.style.display = 'none';
//         Clientname2.style.display = 'none';
//         Clientname3.style.display = 'block';
//         Clientname4.style.display = 'none';
//         Clientname5.style.display = 'none';
//         Clientname6.style.display = 'none';
//         Clientname7.style.display = 'none';
//     } else if (currentId === 'client4') {

//         firstLI.insertAdjacentElement("afterend", Client1Li);

//         Client1Li.insertAdjacentElement("afterend", Client2Li);
//         Client2Li.insertAdjacentElement("afterend", Client3Li);
//         Client3Li.insertAdjacentElement("afterend", Client4Li);
//         Client4Li.insertAdjacentElement("afterend", Client5Li);
//         Client5Li.insertAdjacentElement("afterend", Client6Li);
//         Client6Li.insertAdjacentElement("afterend", Client7Li);




//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = analyticsImg;

//         // anyliticsBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     anyliticsBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);


//         Clientname1.style.display = 'none';
//         Clientname2.style.display = 'none';
//         Clientname3.style.display = 'none';
//         Clientname4.style.display = 'block';
//         Clientname5.style.display = 'none';
//         Clientname6.style.display = 'none';
//         Clientname7.style.display = 'none';
//     } else if (currentId === 'client5') {

//         firstLI.insertAdjacentElement("afterend", Client1Li);

//         Client1Li.insertAdjacentElement("afterend", Client2Li);
//         Client2Li.insertAdjacentElement("afterend", Client3Li);
//         Client3Li.insertAdjacentElement("afterend", Client5Li);
//         Client5Li.insertAdjacentElement("afterend", Client4Li);
//         Client4Li.insertAdjacentElement("afterend", Client6Li);
//         Client6Li.insertAdjacentElement("afterend", Client7Li);



//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = expoImg;

//         // expoBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     expoBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);


//         Clientname1.style.display = 'none';
//         Clientname2.style.display = 'none';
//         Clientname3.style.display = 'none';
//         Clientname4.style.display = 'none';
//         Clientname5.style.display = 'block';
//         Clientname6.style.display = 'none';
//         Clientname7.style.display = 'none';
//     } else if (currentId === 'client6') {

//         firstLI.insertAdjacentElement("afterend", Client1Li);

//         Client1Li.insertAdjacentElement("afterend", Client2Li);
//         Client2Li.insertAdjacentElement("afterend", Client3Li);
//         Client3Li.insertAdjacentElement("afterend", Client6Li);
//         Client6Li.insertAdjacentElement("afterend", Client4Li);
//         Client4Li.insertAdjacentElement("afterend", Client5Li);
//         Client5Li.insertAdjacentElement("afterend", Client7Li);

//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = udyogImg;

//         // hologramBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     hologramBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);


//         Clientname1.style.display = 'none';
//         Clientname2.style.display = 'none';
//         Clientname3.style.display = 'none';
//         Clientname4.style.display = 'none';
//         Clientname5.style.display = 'none';
//         Clientname6.style.display = 'block';
//         Clientname7.style.display = 'none';
//     } else if (currentId === 'client7') {

//         firstLI.insertAdjacentElement("afterend", Client2Li);
//         Client2Li.insertAdjacentElement("afterend", Client3Li);

//         Client3Li.insertAdjacentElement("afterend", Client4Li);
//         Client4Li.insertAdjacentElement("afterend", Client7Li);
//         Client7Li.insertAdjacentElement("afterend", Client5Li);
//         Client5Li.insertAdjacentElement("afterend", Client6Li);

//         Client6Li.insertAdjacentElement("afterend", Client1Li);







//         Clientname1.style.display = 'none';
//         Clientname2.style.display = 'none';
//         Clientname3.style.display = 'none';
//         Clientname4.style.display = 'none';
//         Clientname5.style.display = 'none';
//         Clientname6.style.display = 'none';
//         Clientname7.style.display = 'block';
//     }
// }





// window.changeContent2 = function changeContent2(currentId) {



//     var Clientname8 = document.getElementById("clientname8");
//     var Clientname9 = document.getElementById("clientname9");
//     var Clientname10 = document.getElementById("clientname10");
//     var Clientname11 = document.getElementById("clientname11");
//     var Clientname12 = document.getElementById("clientname12");
//     var Clientname13 = document.getElementById("clientname13");

//     var dot4 = document.getElementById("32");
//     var dot5 = document.getElementById("42");
//     var dot6 = document.getElementById("52");
//     var dot1 = document.getElementById("62");
//     var dot2 = document.getElementById("72");
//     var dot3 = document.getElementById("82");


//     var isPaused = false;

//     var list = document.getElementById("product_listclients2");

//     list.addEventListener("click", function (e) {
//         if (e.target && e.target.matches("li.item")) {
//             e.target.className = "foo";

//             alert("clicked " + e.target.innerText);
//         }
//     });

//     window.isPaused = true;


//     var firstLI = document.getElementById("product_listclients2").firstElementChild;

//     var Client8Li = document.getElementById("client8");
//     var Client9Li = document.getElementById("client9");
//     var Client10Li = document.getElementById("client10");
//     var Client11Li = document.getElementById("client11");
//     var Client12Li = document.getElementById("client12");
//     var Client13Li = document.getElementById("client13");


//     if (currentId === 'client8'  || currentId === "62") {

//         firstLI.insertAdjacentElement("afterend", Client11Li);

//         Client11Li.insertAdjacentElement("afterend", Client12Li);
//         Client12Li.insertAdjacentElement("afterend", Client13Li);
//         Client13Li.insertAdjacentElement("afterend", Client8Li);
//         Client8Li.insertAdjacentElement("afterend", Client9Li);

//         Client9Li.insertAdjacentElement("afterend", Client10Li);


//         dot1.classList.add('dotactive');
//         dot5.classList.remove('dotactive');
//         dot6.classList.remove('dotactive');
//         dot4.classList.remove('dotactive');
//         dot2.classList.remove('dotactive');
//         dot3.classList.remove('dotactive');

//         Clientname8.style.display = 'block';
//         Clientname9.style.display = 'none';
//         Clientname10.style.display = 'none';
//         Clientname11.style.display = 'none';
//         Clientname12.style.display = 'none';
//         Clientname13.style.display = 'none';


//     } else if (currentId === 'client9' || currentId === "72") {

//         firstLI.insertAdjacentElement("afterend", Client12Li);

//         Client12Li.insertAdjacentElement("afterend", Client13Li);
//         Client13Li.insertAdjacentElement("afterend", Client8Li);
//         Client8Li.insertAdjacentElement("afterend", Client9Li);
//         Client9Li.insertAdjacentElement("afterend", Client10Li);
//         Client10Li.insertAdjacentElement("afterend", Client11Li);

//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = hologramImg;

//         // hologramBlock.classList.add('slide-in');

//         // setTimeout(() => {
//         //     hologramBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);
//         dot1.classList.remove('dotactive');
//         dot2.classList.add('dotactive');
//         dot3.classList.remove('dotactive');
//         dot4.classList.remove('dotactive');
//         dot5.classList.remove('dotactive');
//         dot6.classList.remove('dotactive');
//         Clientname8.style.display = 'none';
//         Clientname9.style.display = 'block';
//         Clientname10.style.display = 'none';
//         Clientname11.style.display = 'none';
//         Clientname12.style.display = 'none';
//         Clientname13.style.display = 'none';

//     } else if (currentId === 'client10' || currentId === "82") {

//         firstLI.insertAdjacentElement("afterend", Client13Li);

//         Client13Li.insertAdjacentElement("afterend", Client8Li);
//         Client8Li.insertAdjacentElement("afterend", Client9Li);

//         Client9Li.insertAdjacentElement("afterend", Client10Li);
//         Client10Li.insertAdjacentElement("afterend", Client11Li);
//         Client11Li.insertAdjacentElement("afterend", Client12Li);


//         dot1.classList.remove('dotactive');
//         dot2.classList.remove('dotactive');
//         dot3.classList.add('dotactive');
//         dot4.classList.remove('dotactive');
//         dot5.classList.remove('dotactive');
//         dot6.classList.remove('dotactive');
//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = uniteplusImg;

//         // guruBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     guruBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);


//         Clientname8.style.display = 'none';
//         Clientname9.style.display = 'none';
//         Clientname10.style.display = 'block';
//         Clientname11.style.display = 'none';
//         Clientname12.style.display = 'none';
//         Clientname13.style.display = 'none';

//     } else if (currentId === 'client11' || currentId === "32") {

//         firstLI.insertAdjacentElement("afterend", Client8Li);

//         Client8Li.insertAdjacentElement("afterend", Client9Li);
//         Client9Li.insertAdjacentElement("afterend", Client10Li);

//         Client10Li.insertAdjacentElement("afterend", Client11Li);
//         Client11Li.insertAdjacentElement("afterend", Client12Li);
//         Client12Li.insertAdjacentElement("afterend", Client13Li);




//         dot1.classList.remove('dotactive');
//         dot2.classList.remove('dotactive');
//         dot3.classList.remove('dotactive');
//         dot4.classList.add('dotactive');
//         dot5.classList.remove('dotactive');
//         dot6.classList.remove('dotactive');
//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = analyticsImg;

//         // anyliticsBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     anyliticsBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);


//         Clientname8.style.display = 'none';
//         Clientname9.style.display = 'none';
//         Clientname10.style.display = 'none';
//         Clientname11.style.display = 'block';
//         Clientname12.style.display = 'none';
//         Clientname13.style.display = 'none';

//     } else if (currentId === 'client12' || currentId === "42") {

//         firstLI.insertAdjacentElement("afterend", Client9Li);


//         Client9Li.insertAdjacentElement("afterend", Client10Li);
//         Client10Li.insertAdjacentElement("afterend", Client11Li);
//         Client11Li.insertAdjacentElement("afterend", Client12Li);
//         Client12Li.insertAdjacentElement("afterend", Client13Li);
//         Client13Li.insertAdjacentElement("afterend", Client8Li);


//         dot1.classList.remove('dotactive');
//         dot2.classList.remove('dotactive');
//         dot3.classList.remove('dotactive');
//         dot4.classList.remove('dotactive');
//         dot5.classList.add('dotactive');
//         dot6.classList.remove('dotactive');

//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = expoImg;

//         // expoBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     expoBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);


//         Clientname8.style.display = 'none';
//         Clientname9.style.display = 'none';
//         Clientname10.style.display = 'none';
//         Clientname11.style.display = 'none';
//         Clientname12.style.display = 'block';
//         Clientname13.style.display = 'none';

//     } else if (currentId === 'client13' || currentId === "52") {

//         firstLI.insertAdjacentElement("afterend", Client10Li);

//         Client10Li.insertAdjacentElement("afterend", Client11Li);
//         Client11Li.insertAdjacentElement("afterend", Client12Li);
//         Client12Li.insertAdjacentElement("afterend", Client13Li);
//         Client13Li.insertAdjacentElement("afterend", Client8Li);
//         Client8Li.insertAdjacentElement("afterend", Client9Li);
//         // Client12Li.insertAdjacentElement("afterend", firstLI);

//         // var imgEl = document.getElementById('productImage');
//         // imgEl.classList.add('rotate');
//         // imgEl.src = udyogImg;

//         // hologramBlock.classList.add('slide-in');
//         // setTimeout(() => {
//         //     hologramBlock.classList.remove('slide-in');
//         //     imgEl.classList.remove('rotate');
//         // }, 3000);
//  dot1.classList.remove('dotactive');
//         dot2.classList.remove('dotactive');
//         dot3.classList.remove('dotactive');
//         dot4.classList.remove('dotactive');
//         dot5.classList.remove('dotactive');
//         dot6.classList.add('dotactive');

//         Clientname8.style.display = 'none';
//         Clientname9.style.display = 'none';
//         Clientname10.style.display = 'none';
//         Clientname11.style.display = 'none';
//         Clientname12.style.display = 'none';
//         Clientname13.style.display = 'block';

//     }
// }







// window.mobchangeContent1 = function mobchangeContent1(currentId) {




//     var mobClientname1 = document.getElementById("mobclientname1");
//     var mobClientname2 = document.getElementById("mobclientname2");
//     var mobClientname3 = document.getElementById("mobclientname3");

//     var isPaused = false;

//     var list = document.getElementById("mob_product_listclients1");

//     list.addEventListener("click", function (e) {
//         if (e.target && e.target.matches("li.item")) {
//             e.target.className = "foo";
//             alert("clicked " + e.target.innerText);
//         }
//     });

//     window.isPaused = true;

//     var firstLI = document.getElementById("mob_product_listclients1").firstElementChild;

//     var mobClient1Li = document.getElementById("mclient1");
//     var mobClient2Li = document.getElementById("mclient2");
//     var mobClient3Li = document.getElementById("mclient3");

//     setTimeout(() => {
//         window.isPaused = false;

//     }, 15000);

//     if (currentId === 'mclient1') {

//         firstLI.insertAdjacentElement("afterend", mobClient2Li);

//         mobClient2Li.insertAdjacentElement("afterend", mobClient1Li);
//         mobClient1Li.insertAdjacentElement("afterend", mobClient3Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname1.style.display = 'block';
//         mobClientname2.style.display = 'none';
//         mobClientname3.style.display = 'none';
//     } else if (currentId === 'mclient2') {

//         firstLI.insertAdjacentElement("afterend", mobClient1Li);

//         mobClient1Li.insertAdjacentElement("afterend", mobClient2Li);
//         mobClient2Li.insertAdjacentElement("afterend", mobClient3Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname1.style.display = 'none';
//         mobClientname2.style.display = 'block';
//         mobClientname3.style.display = 'none';

//     } else if (currentId === 'mclient3') {

//         firstLI.insertAdjacentElement("afterend", mobClient1Li);

//         mobClient1Li.insertAdjacentElement("afterend", mobClient3Li);
//         mobClient3Li.insertAdjacentElement("afterend", mobClient2Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname1.style.display = 'none';
//         mobClientname2.style.display = 'none';
//         mobClientname3.style.display = 'block';
//     }
// }
// window.mobchangeContent2 = function mobchangeContent2(currentId) {




//     var mobClientname4 = document.getElementById("mobclientname4");
//     var mobClientname5 = document.getElementById("mobclientname5");
//     var mobClientname6 = document.getElementById("mobclientname6");

//     var isPaused = false;

//     var list = document.getElementById("mob_product_listclients2");

//     list.addEventListener("click", function (e) {
//         if (e.target && e.target.matches("li.item")) {
//             e.target.className = "foo";
//             alert("clicked " + e.target.innerText);
//         }
//     });

//     window.isPaused = true;

//     var firstLI = document.getElementById("mob_product_listclients2").firstElementChild;

//     var mobClient4Li = document.getElementById("mclient4");
//     var mobClient5Li = document.getElementById("mclient5");
//     var mobClient6Li = document.getElementById("mclient6");

//     setTimeout(() => {
//         window.isPaused = false;

//     }, 15000);

//     if (currentId === 'mclient4') {

//         firstLI.insertAdjacentElement("afterend", mobClient5Li);

//         mobClient5Li.insertAdjacentElement("afterend", mobClient4Li);
//         mobClient4Li.insertAdjacentElement("afterend", mobClient6Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname4.style.display = 'block';
//         mobClientname5.style.display = 'none';
//         mobClientname6.style.display = 'none';
//     } else if (currentId === 'mclient5') {

//         firstLI.insertAdjacentElement("afterend", mobClient4Li);

//         mobClient4Li.insertAdjacentElement("afterend", mobClient5Li);
//         mobClient5Li.insertAdjacentElement("afterend", mobClient6Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname4.style.display = 'none';
//         mobClientname5.style.display = 'block';
//         mobClientname6.style.display = 'none';

//     } else if (currentId === 'mclient6') {

//         firstLI.insertAdjacentElement("afterend", mobClient4Li);

//         mobClient4Li.insertAdjacentElement("afterend", mobClient6Li);
//         mobClient6Li.insertAdjacentElement("afterend", mobClient5Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname4.style.display = 'none';
//         mobClientname5.style.display = 'none';
//         mobClientname6.style.display = 'block';
//     }
// }
// window.mobchangeContent3 = function mobchangeContent3(currentId) {

//     var mobClientname7 = document.getElementById("mobclientname7");
//     var mobClientname8 = document.getElementById("mobclientname8");
//     var mobClientname9 = document.getElementById("mobclientname9");

//     var isPaused = false;

//     var list = document.getElementById("mob_product_listclients3");

//     list.addEventListener("click", function (e) {
//         if (e.target && e.target.matches("li.item")) {
//             e.target.className = "foo";
//             alert("clicked " + e.target.innerText);
//         }
//     });

//     window.isPaused = true;

//     var firstLI = document.getElementById("mob_product_listclients3").firstElementChild;

//     var mobClient7Li = document.getElementById("mclient7");
//     var mobClient8Li = document.getElementById("mclient8");
//     var mobClient9Li = document.getElementById("mclient9");

//     setTimeout(() => {
//         window.isPaused = false;

//     }, 15000);

//     if (currentId === 'mclient7') {

//         firstLI.insertAdjacentElement("afterend", mobClient8Li);

//         mobClient8Li.insertAdjacentElement("afterend", mobClient7Li);
//         mobClient7Li.insertAdjacentElement("afterend", mobClient9Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname7.style.display = 'block';
//         mobClientname8.style.display = 'none';
//         mobClientname9.style.display = 'none';
//     } else if (currentId === 'mclient8') {

//         firstLI.insertAdjacentElement("afterend", mobClient7Li);

//         mobClient7Li.insertAdjacentElement("afterend", mobClient8Li);
//         mobClient8Li.insertAdjacentElement("afterend", mobClient9Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname7.style.display = 'none';
//         mobClientname8.style.display = 'block';
//         mobClientname9.style.display = 'none';

//     } else if (currentId === 'mclient9') {

//         firstLI.insertAdjacentElement("afterend", mobClient7Li);

//         mobClient7Li.insertAdjacentElement("afterend", mobClient9Li);
//         mobClient9Li.insertAdjacentElement("afterend", mobClient8Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname7.style.display = 'none';
//         mobClientname8.style.display = 'none';
//         mobClientname9.style.display = 'block';
//     }
// }
// window.mobchangeContent4 = function mobchangeContent4(currentId) {




//     var mobClientname10 = document.getElementById("mobclientname10");
//     var mobClientname11 = document.getElementById("mobclientname11");
//     var mobClientname12 = document.getElementById("mobclientname12");

//     var isPaused = false;

//     var list = document.getElementById("mob_product_listclients1");

//     list.addEventListener("click", function (e) {
//         if (e.target && e.target.matches("li.item")) {
//             e.target.className = "foo";
//             alert("clicked " + e.target.innerText);
//         }
//     });

//     window.isPaused = true;

//     var firstLI = document.getElementById("mob_product_listclients4").firstElementChild;

//     var mobClient10Li = document.getElementById("mclient10");
//     var mobClient11Li = document.getElementById("mclient11");
//     var mobClient12Li = document.getElementById("mclient12");

//     setTimeout(() => {
//         window.isPaused = false;

//     }, 15000);

//     if (currentId === 'mclient10') {

//         firstLI.insertAdjacentElement("afterend", mobClient11Li);

//         mobClient11Li.insertAdjacentElement("afterend", mobClient10Li);
//         mobClient10Li.insertAdjacentElement("afterend", mobClient12Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname10.style.display = 'block';
//         mobClientname11.style.display = 'none';
//         mobClientname12.style.display = 'none';
//     } else if (currentId === 'mclient11') {

//         firstLI.insertAdjacentElement("afterend", mobClient10Li);

//         mobClient10Li.insertAdjacentElement("afterend", mobClient11Li);
//         mobClient11Li.insertAdjacentElement("afterend", mobClient12Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname10.style.display = 'none';
//         mobClientname11.style.display = 'block';
//         mobClientname12.style.display = 'none';

//     } else if (currentId === 'mclient12') {

//         firstLI.insertAdjacentElement("afterend", mobClient10Li);

//         mobClient10Li.insertAdjacentElement("afterend", mobClient12Li);
//         mobClient12Li.insertAdjacentElement("afterend", mobClient11Li);

//         setTimeout(() => {
//             creatorBlock.classList.remove('slide-in');
//             imgEl.classList.remove('rotate');
//         }, 3000);

//         mobClientname10.style.display = 'none';
//         mobClientname11.style.display = 'none';
//         mobClientname12.style.display = 'block';
//     }
// }